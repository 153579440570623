<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6">
        <b-form @submit.stop.prevent="handleSubmit">
          <b-card>
            <div slot="header">
              <strong>
                {{
                  $route.name == route.form
                    ? "Create New Unit"
                    : "Update Unit"
                }}
              </strong>
            </div>
            <b-row>
              <BuildingDropdown :server-params="form"
                                v-if="$route.name == route.form"
                                :form-group="true" />
              <b-col sm="12" v-else>
                <form-group
                            label="Building"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                readonly
                                v-model="form.buildingName"
                                autocomplete="off"></b-form-input>
                </form-group>
              </b-col>
              <b-col sm="12">
                <form-group :validator="$v.form.unit"
                            label="Unit#"
                            :use-horizontal="false">
                  <b-form-input type="text"
                                placeholder="Enter unit#"
                                autocomplete="off"
                                v-model="form.unit"></b-form-input>
                </form-group>
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button @click="$router.go(-1)" size="sm"> Back </b-button>
              <b-button type="submit"
                        size="sm"
                        variant="success"
                        class="float-right">
                {{ $route.name == route.form ? "Submit" : "Save Changes" }}
              </b-button>
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import { required } from "vuelidate/lib/validators";
  import BuildingDropdown from "@/components/common/BuildingDropdown";
  export default {
    data: () => ({
      route: {
        form: "UnitManagementCreate",
        table: "UnitManagement",
      },
      form: {
        buildingId: null,
        unit: null,
      },
    }),
    validations: {
      form: {
        unit: { required },
      },
    },
    components: {
      BuildingDropdown,
    },
    created() {
      const self = this;
      if (self.$route.name != self.route.form) {
        self.get();
      }
    },
    methods: {
      get() {
        const self = this;

        let loader = self.$loading.show();
       
        self.$store
          .dispatch("apis/get", {
            url: `common/GetUnitById`,
            params: {
              id: self.$route.params.id,
              buildingId: self.$route.params.buildingId,
            }
          })
          .then((response) => {
            if (response.error) {
              self.$message.error({
                zIndex: 10000,
                message: response.message,
              });

              self.$router.push({ name: self.route.table });
            } else {
              self.form = {
                buildingId: response.data.buildingId,
                unit: response.data.unit,
                buildingName: response.data.buildingName,
              };
            }
            loader.hide();
          });
      },
      handleSubmit() {
        const self = this;

        self.$v.form.$touch();
        if (self.$v.form.$pending || self.$v.form.$error) return;

        let _confirmText = "",
          _okText = "",
          _action = "",
          _url = "";

        if (self.$route.name == self.route.form) {
          _confirmText = "You are about to submit this unit. Are you sure ?";
          _okText = "Yes, Submit";
          _action = "apis/post";
          _url = "common/unit";
        } else {
          _confirmText = "You are about to update this unit. Are you sure ?";
          _okText = "Yes, Update";
          _action = "apis/put";
          const encodedUnit = encodeURIComponent(self.$route.params.id);
          _url = `common/unit?id=${encodedUnit}`;
        }
        self.$dialog
          .confirm(_confirmText, {
            okText: _okText,
            cancelText: "Cancel",
            loader: true,
          })
          .then((dialog) => {
            self.$store
              .dispatch(_action, {
                url: _url,
                params: self.form,
              })
              .then((response) => {
                dialog.close();
                if (response.error) {
                  self.$message.error({
                    zIndex: 10000,
                    message: response.message,
                  });
                } else {
                  self.$message.success({
                    zIndex: 10000,
                    message: response.message,
                  });

                  self.$router.go(-1);
                }
              });
          });
      },
    },
  };
</script>
